/* eslint-disable react-hooks/rules-of-hooks */
// import { useMutation } from "@apollo/client"
import React from "react"
// import { UpsertEntryMutation, UpsertEntryMutationVariables } from "../../__generated__/UpsertEntryMutation"
// import { IBracketVisualOptions, IPickUtilsPicks, TNumberMapping } from "../../common/common-utils-types.d"
// import { emptyArray } from "../../common/misc-utils"
// import PickUtils from "../../common/pick-utils"
// import BracketSkeleton from "../App/PoolPages/containers/BracketSkeleton.tsx.orig"
// import { TOnInputChange } from "../App/PoolPages/containers/IPicksProps"
// import { UPSERT_ENTRY_MUTATION } from "../App/queries"
// import Code from "../components/Code"
// import LoadingView from "../components/LoadingView"
// import usePoolData, { buildPickUtils } from "../hooks/usePoolData"
// import { canUseDom } from "../utils/misc-utils"
import { VerticalOverflowContainer } from "../components/OverflowContainer"

const renders = {
  test: 0,
  val1: 0,
  val2: 0,
}
export const savePicksLabel = "savePicks"

// const svgStyles = {width: "50vw", height: "50vw", margin: "5vh auto", display: "block"}
// const sizeScale = 1.0;
// const gwidth = (350 * 3) * sizeScale;
// const gheight = 350 * sizeScale;
// const gwidth = 350 * 2 * sizeScale;
// const gheight = 350 * 2 * sizeScale;
// const isTree = true;
// const scale = 100.0;
// const svgStyles = {width: `${gwidth}px`, height: `${gheight}px`, margin: "5vh auto", display: "block"};

// const emptyArray = [] as never[];
// const debugLabel = "render"

// const matchupWidthPercent = 2 / 3 * 10;
// const gutterWidth = 12;
// const matchupHeight = 100;
// const maxWidth = 1214;
// const versions = [
//   {
//     pathname: "/college-basketball/ncaa-tournament/bracket/challenge",
//     name: "NCAA Bracket",
//     options: {
//       width: 1214,
//       height: 2129,
//       scale: 100.0,
//       matchupHtoWRatio: 1.0,
//       gutterToColumnWRatio: 0,
//       verticalGutterHeight: 12,
//       matchupWidth: 183,
//       matchupHeight: 100,
//       rowHeight: 100 + 12,
//       regionLabelHeight: 18,
//       isStaggered: true,
//       minWidth: 959,
//       finalRoundLogoHeight: 120,
//       finalMatchupWidthRatio: 388 / 1214,
//     },
//   },
//   {
//     pathname: "/college-basketball/ncaa-tournament/bracket/challenge",
//     name: "Simple NCAA Bracket",
//     options: {
//       width: 1600,
//       height: 0,
//       scale: 1225.0,
//       matchupHtoWRatio: 0.5,
//       gutterToColumnWRatio: 0.2,
//     },
//   },
// ]

function Scratchpad(props: any) {
  if (renders.test === 0) {
    // const Comp = Logos.bpc
    return (
      <VerticalOverflowContainer style={{ maxHeight: `200px`, maxWidth: `200px`, color: "white", margin: "20vw" }}>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
        <p>aoifwiofjwjfwofijwfw</p>
      </VerticalOverflowContainer>
    )
  }
  // if (renders.test == 0) {
  //   const version = versions[0]!
  //   console.time(debugLabel)
  //   const poolData = usePoolData(
  //     Object.assign({}, props, {
  //       location: {
  //         pathname: version.pathname,
  //         search: "",
  //         history: null,
  //       },
  //     }),
  //   )
  //   const { detailedPeriod, detailedEntry, gameInstanceUid, poolId } = poolData
  //   const [upsertEntryMutation] = useMutation<UpsertEntryMutation, UpsertEntryMutationVariables>(UPSERT_ENTRY_MUTATION)
  //   console.timeLog(debugLabel, "bracket")
  //   const timeoutIds = React.useRef<TNumberMapping>({
  //     save: 0,
  //     // modalOpen: 0,
  //     // saveAnimation: 0,
  //     // resize: 0,
  //     // processDOM: 0,
  //   })
  //   const [wipPicks, setWipPicks] = React.useState<IPickUtilsPicks>(detailedEntry?.picks || emptyArray)
  //   // const modalActions = useLocalKey("modal");
  //   const [versionOfUi, setVersionOfUi] = React.useState<"svg" | "html">("html")
  //   const [isPersistingPicks, setIsPersistingPicks] = React.useState<boolean>(false)
  //   const [isTree, setIsTree] = React.useState<boolean>(true)
  //   const [isStaggered, setisStaggered] = React.useState<boolean>(!!version.options.isStaggered)
  //   const [viewingTournamentRounds, setviewingTournamentRounds] = React.useState<number>(2)
  //   const [sizeScale, setsizeScale] = React.useState<number>(1.0)
  //   const [width, setwidth] = React.useState<number>(version.options.width)
  //   const [height, setheight] = React.useState<number>(version.options.height)
  //   const [scale, setscale] = React.useState<number>(versionOfUi === "html" ? version.options.scale : 100.0)
  //   const [matchupHtoWRatio, setmatchupHtoWRatio] = React.useState<number>(version.options.matchupHtoWRatio) // 0.6875
  //   const [gutterToColumnWRatio, setgutterToColumnWRatio] = React.useState<number>(version.options.gutterToColumnWRatio) // 0.0
  //   console.timeLog(debugLabel, "pickUtils")
  //   const currentPickUtils = React.useRef<PickUtils | null>(null)
  //   const pickUtils = buildPickUtils(poolData, currentPickUtils.current, wipPicks, detailedEntry?.id)
  //   if (currentPickUtils.current !== pickUtils) {
  //     currentPickUtils.current = pickUtils
  //   }
  //   const clearTimeoutById = (id: string) => {
  //     const curr = timeoutIds.current[id]
  //     if (curr && canUseDom) {
  //       window.clearTimeout(curr)
  //       timeoutIds.current[id] = 0
  //     }
  //   }
  //   const setTimeoutById = (id: string, ms: number, handler: any) => {
  //     if (timeoutIds.current && canUseDom) {
  //       clearTimeoutById(id)
  //       timeoutIds.current[id] = window.setTimeout(handler, ms)
  //     }
  //   }
  //   // const getPendingPicks = () => pickUtils?.getDiffFrom(wipPicks);
  //   const persistPendingPicks = async (_id: number, force = false) => {
  //     const persistedPicks = detailedEntry?.picks || emptyArray
  //     const picksToPersist = pickUtils?.getDiffFrom(persistedPicks) || emptyArray
  //     console.log(`persistPendingPicks`)
  //     console.dir(persistedPicks)
  //     console.dir(wipPicks)
  //     console.dir(picksToPersist)
  //     if (picksToPersist.length || force === true) {
  //       setIsPersistingPicks(true)
  //       const periodId = detailedPeriod!.id
  //       const entryId = detailedEntry!.id
  //       const variables = {
  //         entryId,
  //         gameInstanceUid,
  //         poolId,
  //         periodId,
  //         picks: picksToPersist,
  //         tiebreakerAnswers: null,
  //       } as any
  //       if (!picksToPersist.length && !!variables.tiebreakerAnswers) {
  //         // NOTE qac: this is a tiebreaker only submital (no changes)
  //         delete variables.picks
  //       }
  //       try {
  //         const res = await upsertEntryMutation({ variables })
  //         console.dir(res)
  //       } catch (ee) {
  //         console.error(ee)
  //         window.SH_ERROR_NOTIFY(ee, "pick-saving")
  //       }
  //       clearTimeoutById("save")
  //       setIsPersistingPicks(false)
  //     } else {
  //       clearTimeoutById("save")
  //     }
  //   }
  //   const queuePickPersisting = (ms: number) => {
  //     // console.debug(`queuePickPersisting: ${ms} (${this.canQueuePersistingPicks}, ${this.mounted})`);
  //     setTimeoutById("save", ms, persistPendingPicks)
  //   }
  //   const onItemClick = React.useCallback<TOnInputChange>((slotId, itemId, event) => {
  //     console.time(savePicksLabel)
  //     event.preventDefault()
  //     event.stopPropagation()
  //     const eventId = pickUtils?.slotIdForEventId(slotId)
  //     console.timeLog(savePicksLabel, `onItemClick: eventId`)
  //     if (eventId && pickUtils) {
  //       const changes = pickUtils.setChanges(eventId, itemId)
  //       const merged = pickUtils.getMergedPicks(changes)
  //       console.timeLog(savePicksLabel, `onItemClick: merged`)
  //       try {
  //         pickUtils.validate(changes)
  //         // this.clearErrors();
  //         console.timeLog(savePicksLabel, `setting picks`)
  //         console.dir(changes)
  //         setWipPicks(merged)
  //         console.timeLog(savePicksLabel, `set picks`)
  //         if (changes.length === -1) {
  //           queuePickPersisting(2200)
  //         }
  //       } catch (err) {
  //         // this.showError(err.message);
  //         window.SH_ERROR_NOTIFY(err)
  //       }
  //     }
  //     console.timeEnd(savePicksLabel)
  //     return Promise.resolve()
  //   }, [])
  //   const onFormSubmit = React.useCallback<React.FormEventHandler>((event) => {
  //     event.preventDefault()
  //     console.log(`onFormSubmit:`)
  //   }, [])
  //   if (!pickUtils || !pickUtils.bracketPeriodTree) {
  //     return <LoadingView />
  //   }
  //   console.timeLog(debugLabel, "buildCoordinateMap start")
  //   const options = {
  //     width,
  //     height,
  //     scale,
  //     isTree,
  //     isStaggered,
  //     matchupHtoWRatio,
  //     gutterToColumnWRatio,
  //     verticalGutterHeight: version.options.verticalGutterHeight,
  //     regionLabelHeight: version.options.regionLabelHeight,
  //     matchupHeight: version.options.matchupHeight,
  //     minWidth: version.options.minWidth,
  //     rowHeight: version.options.rowHeight,
  //     swacketViewingTournamentRound: viewingTournamentRounds,
  //   } as Partial<IBracketVisualOptions>
  //   if (version.options.hasOwnProperty("finalMatchupWidthRatio")) {
  //     options.finalMatchupWidthRatio = version.options.finalMatchupWidthRatio
  //   }
  //   if (version.options.hasOwnProperty("finalRoundLogoHeight")) {
  //     options.finalRoundLogoHeight = version.options.finalRoundLogoHeight
  //   }
  //   const bracketPeriodTree = pickUtils.bracketUtils.buildCoordinateMap(pickUtils.bracketPeriodTree, options)
  //   console.timeLog(debugLabel, "buildCoordinateMap end")
  //   if (canUseDom) {
  //     window.pickUtils = pickUtils
  //   }
  //   const changeTournamentRound = React.useCallback(
  //     (newTournamentRound: number) => {
  //       const tr = pickUtils.bracketUtils.clampRound(newTournamentRound, pickUtils.bracketPeriodTree!)
  //       return new Promise<void>((res) => {
  //         setviewingTournamentRounds(tr)
  //         res()
  //       })
  //     },
  //     [pickUtils],
  //   )
  //   console.timeEnd(debugLabel)
  //   return (
  //     <div>
  //       <div style={{ display: "flex", flexFlow: "row wrap", padding: "2rem", justifyContent: "space-evenly" }}>
  //         <label>
  //           viewingTournamentRounds:{" "}
  //           <input type="number" value={viewingTournamentRounds} onChange={(e) => setviewingTournamentRounds(parseFloat(e.target.value))} />
  //         </label>
  //         {isTree === false ? (
  //           <>
  //             <label>
  //               version: <code>{versionOfUi}</code>:{" "}
  //               <input
  //                 type="checkbox"
  //                 checked={versionOfUi === "html"}
  //                 value="html"
  //                 onChange={(e) => {
  //                   setVersionOfUi(e.target.checked ? "html" : "svg")
  //                   setscale(e.target.checked ? version.options.scale : 100.0)
  //                 }}
  //               />
  //             </label>
  //             <label>
  //               isTree: <code>{isTree.toString()}</code>:{" "}
  //               <input type="checkbox" checked={isTree} value="true" onChange={(e) => setIsTree(!!e.target.checked)} />
  //             </label>
  //             <label>
  //               isStaggered: <code>{isStaggered.toString()}</code>:{" "}
  //               <input checked={isStaggered} type="checkbox" value="true" onChange={(e) => setisStaggered(!!e.target.checked)} />
  //             </label>
  //             <label>
  //               width: <input type="number" value={width} onChange={(e) => setwidth(parseFloat(e.target.value))} />
  //             </label>
  //             <label>
  //               height: <input type="number" value={height} onChange={(e) => setheight(parseFloat(e.target.value))} />
  //             </label>
  //             <label>
  //               scale: <input type="number" value={scale} onChange={(e) => setscale(parseFloat(e.target.value))} />
  //             </label>
  //             <label>
  //               sizeScale: <input type="number" value={sizeScale} onChange={(e) => setsizeScale(parseFloat(e.target.value))} />
  //             </label>
  //             <label>
  //               gutterToColumnWRatio:{" "}
  //               <input type="number" value={gutterToColumnWRatio} onChange={(e) => setgutterToColumnWRatio(parseFloat(e.target.value))} />
  //             </label>
  //             <label>
  //               matchupHtoWRatio: <input type="number" value={matchupHtoWRatio} onChange={(e) => setmatchupHtoWRatio(parseFloat(e.target.value))} />
  //             </label>
  //           </>
  //         ) : null}
  //       </div>
  //       <form noValidate={true} onSubmit={onFormSubmit}>
  //         <BracketSkeleton
  //           onItemClick={onItemClick}
  //           pickUtils={pickUtils}
  //           cacheKey={pickUtils.bracketMapping?.cacheKey || ""}
  //           changeTournamentRound={changeTournamentRound}
  //           uiEngine={versionOfUi}
  //           enableDebugMode={true}
  //           displayMode={"interactive"}
  //         />
  //       </form>
  //       {isPersistingPicks ? <code>Autosaving...</code> : null}
  //       <Code>matchups: {JSON.stringify(bracketPeriodTree.visualOptions, null, 2)}</Code>
  //     </div>
  //   )
  // }
  return null
}

/*
import LocalKey from "./LocalKey";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {EntryNameModal} from "../App/PoolSetupPages/components/CreateEntryForm";
import DeviceType from "../Base/DeviceType";
import ActionSheet, {Item} from "../components/ActionSheet";
import Ad from "../components/Ad";
import BannerAd from "../components/BannerAd";
import Carrot from "../components/icons/Carrot";
import Logos from "../components/Logos";
import Analytics from "../utils/analytics";
import Gtm from "../utils/gtm";
import {StorageTypes} from "../utils/local-state";

const guids = ["cbs-nfl-pickem-challenge", "cbs-nfl-parlay-challenge", "cbs-ncaaf-pickem-challenge", "cbs-ncaab-confbracket-challenge"];

const dds = {
  [guids[0]]: {
    title: "Football Pickem",
    hasManager: true,
    logo: Logos["cbs-ncaaf-pickem-manager"],
    entries: [
      {name: "Quinn"},
      {name: "Tiny Tim"},
    ],
  },
  [guids[2]]: {
    title: "College Football",
    logo: Logos["cbs-ncaaf-pickem-challenge"],
    hasManager: true,
    entries: [
    ],
  },
  [guids[1]]: {
    title: "Football Parlay",
    logo: Logos["cbs-nfl-parlay-challenge"],
    hasManager: false,
    entries: [
      {name: "Quinn"},
    ],
  },
  [guids[3]]: {
    title: "Conference Brackets",
    logo: Logos.bpc,
    hasManager: true,
    entries: [
      {name: "Quinn"},
    ],
  },
};

const Styles = styled.div`
background: #f5f6f7;
height: 100vh;
padding: 12px 12px;
color: #13161a;
font-family: ${fontFamily.base};
& section {
  margin: 0 auto 12px;
  background: #fff;
  border-radius: 1px;
  border: 1px solid #dfe2e6;
  max-width: 320px;
  overflow: hidden;
}
& .h {
  display: flex;
  flex-flow: row nowrap;
  height: 54px;
  align-items: center;
  & svg {
    height: 45px;
    width: 45px;
    margin: 0 12px;
  }
}

& h2 {
  font-size: 16px;
}
& .es {
  font-size: 12px;
  border: 1px solid rgba(0,0,0,0.2);
  border-left: none;
  border-right: none;
  box-shadow:
        inset 0px 11px 8px -10px rgba(0,0,0,0.15),
        inset 0px -11px 8px -10px rgba(0,0,0,0.15);
}
& .only-one--true {
  box-shadow: inset 0px 11px 8px -10px rgba(0,0,0,0.15);
  border-bottom: none;
}
& .e {
  display: flex;
  flex-flow: row nowrap;
  height: 45px;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  justify-content: space-between;

  &:last-child {
    border: none;
  }
  & svg {
    display: block;
    width: 16px;
    height: 16px;
  }
}
& .bs {
  display: flex;
  flex-flow: row nowrap;
  height: 54px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 16px;
  & button {
    height: 35px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #2287e6;
    color: #fff;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 2px;
    width: 35%;
  }
}
`;

const typeStorage = "memory";

class Scratchpad extends React.Component<any, any> {
  public render() {
    if (renders.test == 0) {

    } {
      return null;
    } else if (renders.test == 11) {
      return (
        <Styles>
          {guids.map((d) => {
            const dd = dds[d]!;
            const Logo = dd.logo;
            return (
              <section key={d}>
                <div className="h">
                  <Logo />
                  <h2>{dd.title}</h2>
                </div>
                {dd.entries.length ? (
                  <div className={`es only-one--${dd.entries.length === 1 && !dd.hasManager}`}>
                    {dd.entries.map((e) => {
                      return (
                        <div className="e" key={e.name}>
                          {e.name}
                          <Carrot />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {dd.hasManager || dd.entries.length === 0 ? (
                  <div className="bs">
                    {dd.hasManager ? (
                      <button>Start Pool</button>
                    ) : null}
                    {!dd.entries.length ? (
                      <button>Play Now</button>
                    ) : null}
                  </div>
                ) : null}

              </section>
            );
          })}
        </Styles>
      );
    }
    if (renders.test == 1) {
      return (

        <LocalKey keyName="modal">
        {(injectedProps) => {
          console.log(`value: ${injectedProps.value}`);
          return (
            <>
            <pre>value: {injectedProps.value}</pre>
            {guids.map((d) => (
              <button type="button" key={d} onClick={() => injectedProps.update(d)}>{d}</button>
            ))}

            <EntryNameModal
              isOpen={!!injectedProps.value}
              mutation={() => Promise.resolve({})}
              entryId={undefined}
              periodId={"1"}
              gameInstanceUid={injectedProps.value || ""}
              onFinish={() => injectedProps.update(null)}
            />
            </>
          );
        }}
        </LocalKey>

      );
    } else if (renders.test === 5) {
      return (
        <LocalKey
          keyName="test3"
        >
        {(injectedProps) => (
          <div>
          <div id="network-bar-spacer" className="network-bar-spacer" />
          <BannerAd useShSkybox={false} />
          <div id="cbs-site-header" className="cbs-site-header" data-component="nav" data-role="header" data-position="fixed" data-fullscreen="true">
              <div id="cbs-network-bar" className="cbs-network-bar">
                  #cbs-network-bar
              </div>
              <div id="cbs-site-nav" className="cbs-site-nav">
                  #cbs-site-nav
              </div>
          </div>
          <br />
          <button onClick={() => Gtm.refresh()}>Refresh Ads</button>
          <br />
          <button onClick={() => injectedProps.update(parseInt(injectedProps.value || "1") + 1, "queryParam")}>
            toggle ad {injectedProps.value || "1"}
            </button>
          <div style={{height: "150vh"}}>div</div>
          <Code>
          [[728,90],[970,66],[970,90]], "leader_top" "banner"
          [114,36], "logo_top" "logo"
          [320,50], "leader_top" "banner")
          [320,20], "logo_top" "logo"
          </Code>
        </div>
        )}
        </LocalKey>
      );
    }
    if (renders.test == 2) {
      return (
        <ActionSheet
          valueName="test"
          className="test"
          toggler={(props) => <button {...props} as="button">open</button>}
          dropdown={(props) => (
            <div {...props}>
              <Item to={"/"}>items!</Item>
            </div>
          )}
        />
      );
    }
    if (renders.test == 3) {
      return (
          <LocalKey
            keyName="test1"
          >
          {(injectedProps) => (
            <div>
            <button onClick={() => Analytics.trackScreen("team management", "options alerts", "Options - Alerts")}>Options - Alerts</button>
            <br />
            <button onClick={() => Analytics.trackScreen("league management", "edit finances", "Players - Finances")}>Players - Finances</button>
            <br />
            <button onClick={() => Analytics.trackInteraction("make pick")}>Make Pick</button>
            <br />
            <DeviceType>
            {({deviceType}) => {
              return (
                <Ad className={injectedProps.value || "1"} name="leader_top" sizes={deviceType === "handheld" ? "[320,50]" : "[[728,90],[970,66],[970,90]]"} pos="banner" />
              );
            }}
            </DeviceType>
            <br />
            <button onClick={() => Gtm.refresh()}>Refresh Ads</button>
            <br />
            <button onClick={() => injectedProps.update(parseInt(injectedProps.value || "1") + 1, "queryParam")}>
              toggle ad {injectedProps.value || "1"}
              </button>
            <Code>
            [[728,90],[970,66],[970,90]], "leader_top" "banner"
            [114,36], "logo_top" "logo"
            [320,50], "leader_top" "banner")
            [320,20], "logo_top" "logo"
            </Code>
          </div>
          )}
          </LocalKey>
      );
    }
    // console.log(`Scratchpad render`)
    return (
      <div>
        <DeviceType>
        {({deviceType}) => {
          return (
            <pre>dt: {deviceType}</pre>
          );
        }}
        </DeviceType>
        <LocalKey
          keyName="test"
        >
          {(injectedProps) => {

              // console.log(`renderProp test: injectedProps:`, injectedProps);
              // renders.test++;
              return (
              <div>
                <button onClick={() => Analytics.trackScreen("team management", "options alerts", "Options - Alerts")}>Options - Alerts</button>
                <br />
                <Link to="/">Home</Link>
                <div>value: <code>{injectedProps.value}</code></div>
                {
                  ["memory", "persisted", "historyState", "hashParam", "queryParam"].map((format) => (
                    <div key={format}>
                      <button
                        type="button"
                        onClick={() => injectedProps.update(format == injectedProps.value ? null : format, format as StorageTypes)}
                      >
                        {format === injectedProps.value ? "clear" : format}
                      </button>
                    </div>
                  ))
                }
              </div>
            );
          }}
        </LocalKey>
        <LocalKey
          keyName="other"
          debug="val1"
        >
          {(injectedProps) => {
            renders.val1++;
            const value = "val1";
            const isSelected = value === injectedProps.value;
            // console.log(`${value} renderProp other: injectedProps: ${injectedProps.value} ${isSelected}`, injectedProps);
            return (
              <div>
                <div>value: <code>{injectedProps.value}</code></div>
                <button
                  type="button"
                  onClick={() => injectedProps.update(isSelected ? null : value, typeStorage)}
                >
                  {isSelected ? "clear" : value}
                </button>
              </div>
            );
          }}
        </LocalKey>
        <LocalKey
          keyName="other"
          debug="val2"
        >
          {(injectedProps) => {
            renders.val2++;
            const value = "val2";
            const isSelected = value === injectedProps.value;
            // console.log(`${value} renderProp other: injectedProps: ${injectedProps.value} ${isSelected}`, injectedProps);
            return (
              <div>
                <div>value: <code>{injectedProps.value}</code></div>
                <button
                  type="button"
                  onClick={() => injectedProps.update(isSelected ? null : value, typeStorage)}
                >
                  {isSelected ? "clear" : value}
                </button>
              </div>
            );
          }}
        </LocalKey>
        <Route
          children={(routeComponentProps) => (
            <Query query={GET_LOCAL_STATE_QUERY}>
              {((queryProps) => {
                return (<pre>{[
                  "LocalStore",
                  JSON.stringify(getStateFromQuery(queryProps), null, 2),
                  "Router",
                  JSON.stringify(routeComponentProps, null, 2),
                  "renders",
                  JSON.stringify(renders, null, 2),
                ].join("\n")}</pre>);
              })}
            </Query>
          )}
            />
      </div>
    );
  }
}
*/

export default Scratchpad
